<template>
<div>
    <section class="section legal-section">
      <div class="container">
        <h1 class="title is-1 is-family-secondary not-bold">Impressum</h1>
        <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          Angaben gem&auml;&szlig; &sect; 5 TMG
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          SANTORO Design<br />
          Grafikdesign, Webdesign &amp; Webentwicklung<br />
          Ruben Santoro<br />
          Königsbacherstr. 9<br />
          75196 Remchingen
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Kontakt
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Telefon: 0178/1471840<br />
          E-Mail: hi@santoro-design.com
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Umsatzsteuer-ID
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
          DE323233399
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          Haftung f&uuml;r Inhalte
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG 
          f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen 
          verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter 
          jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde 
          Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die 
          auf eine rechtswidrige T&auml;tigkeit hinweisen. Verpflichtungen zur 
          Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen 
          Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist 
          jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung 
          m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden 
          wir diese Inhalte umgehend entfernen.
        </p>
        <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          Haftung f&uuml;r Links
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf 
          deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir 
          f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. 
          F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige 
          Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten 
          Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche 
          Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte 
          waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine 
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch 
          ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. 
          Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links 
          umgehend entfernen.
        </p>
        <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          Urheberrecht
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen 
          Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der
          Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des 
          jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind 
          nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.
          Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, 
          werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte 
          Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine 
          Urheberrechtsverletzung aufmerksam werden, bitten wir um einen 
          entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden 
          wir derartige Inhalte umgehend entfernen.
          <br><br><br><br>
          Quelle: <a href="https://www.e-recht24.de/impressum-generator.html" target="_blank" rel="noopener">e-recht24.de</a>
          <br><br><br><br>
        </p>
      </div>
    </section>
  </div>  
</template>

<script>

export default {
  name: 'ImprintContent',
  components: {
  
  },
  data () {
    return {
  
    }
  },
  mounted () {
    // if route
    let bg = document.querySelector('.body-bg');
    bg.classList.add('pure-bg');
  }
}
</script>

<style lang="scss" scoped>
 
</style>
