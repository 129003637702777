<template>
<div>
    <section class="section privacy-section">
      <div class="container">
        <h1 class="title is-1 is-family-secondary not-bold">Datenschutz&shy;erkl&auml;rung</h1>
       
       <h1 class="title title-step is-size-4 is-family-secondary not-bold">
        1. Datenschutz auf einen Blick
       </h1>
       <h1 class="title title-step is-size-5 is-family-primary">
          Allgemeine Hinweise
      </h1>
       <p class="is-family-primary has-text-black p-after-h p-anim">
         Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.
       </p>
       <h1 class="title title-step is-size-5 is-family-primary">
          Datenerfassung auf dieser Website
      </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?
          <br><br>
          Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum dieser Website entnehmen.
          <br><br>
          Wie erfassen wir Ihre Daten?
          <br><br>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.
          Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
          <br><br>
          Wof&uuml;r nutzen wir Ihre Daten?
          <br><br>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.
          <br><br>
          Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?
          <br><br>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.
          <br><br>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
        </p>
        <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          2. Hosting und Content Delivery Networks (CDN)
        </h1>
        <h1 class="title title-step is-size-5 is-family-primary">
          Externes Hosting
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
          Die personenbezogenen Daten, die auf dieser Website erfasst werden,
          werden auf den Servern des Hosters gespeichert. Hierbei kann es sich
          v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
          Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
          die &uuml;ber eine Website generiert werden, handeln.
          Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserf&uuml;llung
          gegen&uuml;ber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
          lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
          Bereitstellung unseres Online-Angebots durch einen professionellen
          Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
          Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
          Erf&uuml;llung seiner Leistungspflichten erforderlich ist und unsere
          Weisungen in Bezug auf diese Daten befolgen.
        </p>
         <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          3. Allgemeine Hinweise und Pflicht&shy;informationen
        </h1>
        <h1 class="title title-step is-size-5 is-family-primary">
          Datenschutz
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
          Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
          vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften
          sowie dieser Datenschutzerkl&auml;rung. Wenn Sie diese Website benutzen,
          werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
          sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen.
          Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir
          erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu
          welchem Zweck das geschieht. Wir weisen darauf hin, dass die
          Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per
          E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz
          der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Hinweis zur verantwortlichen Stelle
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser
          Website ist:
          <br><br>
          SANTORO Design<br />
          Ruben Santoro<br />
          Königsbacherstr. 74<br />
          75196 Remchingen
          <br><br>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
          die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
          entscheidet.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
          bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
          Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
          Direktwerbung (Art. 21 DSGVO)
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
          ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
          SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
          WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
          PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
          ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
          WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
          NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
          VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
          RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
          SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
          BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
          EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
          VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
          ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
          NACH ART. 21 ABS. 2 DSGVO).
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Beschwerderecht bei der zuständigen Aufsichtsbehörde
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
          Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
          verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Recht auf Datenübertragbarkeit
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
          automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
          verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          SSL- bzw. TLS-Verschlüsselung
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
          Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung.
          Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
          „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
          von Dritten mitgelesen werden.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Auskunft, Löschung und Berichtigung
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
          Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
          Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
          zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum
          angegebenen Adresse an uns wenden.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Recht auf Einschränkung der Verarbeitung
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht
          auf Einschränkung der Verarbeitung besteht in folgenden Fällen:
        </p>
        <ul>
          <li>
            <p class="is-family-primary has-text-black p-after-h p-anim">
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel
              Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der
              Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </p>
          </li>
          <li>
            <p class="is-family-primary has-text-black p-after-h p-anim">
              Wenn die Verarbeitung Ihrer personenbezogenen Daten
              unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der
              L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.
            </p>
          </li>
          <li>
            <p class="is-family-primary has-text-black p-after-h p-anim">
              Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen,
              Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung
              von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt
              der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </p>
          </li>
          <li>
            <p class="is-family-primary has-text-black p-after-h p-anim">
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
              &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </p>
          </li>
        </ul>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.
        </p>
        <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          4. Datenerfassung auf dieser Website
        </h1>
        <h1 class="title title-step is-size-5 is-family-primary">
          Cookies
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;.
          Cookies sind kleine Textdateien und richten auf Ihrem Endger&auml;t 
          keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die
          Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente 
          Cookies) auf Ihrem Endger&auml;t gespeichert. Session-Cookies werden 
          nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies 
          bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst 
          l&ouml;schen&nbsp;oder eine automatische L&ouml;schung durch Ihren 
          Webbrowser erfolgt. Teilweise k&ouml;nnen auch Cookies von 
          Drittunternehmen auf Ihrem Endger&auml;t gespeichert werden, wenn 
          Sie unsere Seite betreten (Third-Party-Cookies). Diese 
          erm&ouml;glichen uns oder Ihnen die Nutzung bestimmter 
          Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung 
          von Zahlungsdienstleistungen). Cookies haben verschiedene 
          Funktionen. Zahlreiche Cookies sind technisch notwendig, da 
          bestimmte Websitefunktionen ohne diese nicht funktionieren 
          w&uuml;rden (z.B. die Warenkorbfunktion oder die Anzeige von
          Videos). Andere Cookies dienen dazu, das Nutzerverhalten 
          auszuwerten&nbsp;oder Werbung anzuzeigen. Cookies, die zur
          Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs 
          (notwendige Cookies) oder zur Bereitstellung bestimmter, von 
          Ihnen erw&uuml;nschter Funktionen (funktionale Cookies, z. B. 
          f&uuml;r die Warenkorbfunktion) oder zur Optimierung der 
          Website (z.B. Cookies zur Messung des Webpublikums) 
          erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit.
          f DSGVO gespeichert, sofern keine andere Rechtsgrundlage 
          angegeben wird. Der Websitebetreiber hat ein berechtigtes 
          Interesse an der Speicherung von Cookies zur technisch 
          fehlerfreien und optimierten Bereitstellung seiner Dienste. 
          Sofern eine Einwilligung zur Speicherung von Cookies abgefragt 
          wurde, erfolgt die Speicherung der betreffenden Cookies 
          ausschlie&szlig;lich auf Grundlage dieser Einwilligung 
          (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit
          widerrufbar. Sie k&ouml;nnen Ihren Browser so einstellen,
          dass Sie &uuml;ber das Setzen von Cookies informiert werden
          und Cookies nur im Einzelfall erlauben, die Annahme von 
          Cookies f&uuml;r bestimmte F&auml;lle oder generell 
          ausschlie&szlig;en sowie das automatische L&ouml;schen der 
          Cookies beim Schlie&szlig;en des Browsers aktivieren. Bei 
          der Deaktivierung von Cookies kann die Funktionalit&auml;t 
          dieser Website eingeschr&auml;nkt sein. Soweit 
          Cookies von Drittunternehmen oder zu Analysezwecken 
          eingesetzt werden, werden wir Sie hier&uuml;ber im Rahmen
          dieser Datenschutzerkl&auml;rung gesondert informieren
          und ggf. eine Einwilligung abfragen.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Kontaktformular
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
          Angaben aus dem Anfrageformular inklusive der von Ihnen dort 
          angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r 
          den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir 
          nicht ohne Ihre Einwilligung weiter. Die Verarbeitung dieser Daten 
          erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage 
          mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur
          Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In
          allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
          Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei 
          uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
          Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
          entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
          Zwingende gesetzliche Bestimmungen &ndash; insbesondere Aufbewahrungsfristen 
          &ndash; bleiben unber&uuml;hrt.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Anfrage per E-Mail oder Telefon
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten 
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns 
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre 
          Einwilligung weiter. Die Verarbeitung dieser Daten erfolgt auf 
          Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der 
          Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur 
          Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist.
          In allen &uuml;brigen F&auml;llen beruht die Verarbeitung auf unserem
          berechtigten Interesse an der effektiven Bearbeitung der an uns
          gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
          Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten
          verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre
          Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die
          Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener
          Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen
          &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben
          unber&uuml;hrt.
        </p>
        <h1 class="title title-step is-size-4 is-family-secondary not-bold">
          5. Plugins und Tools
        </h1>
        <h1 class="title title-step is-size-5 is-family-primary">
          Google Web Fonts
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so 
          genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf 
          einer Seite l&auml;dt Ihr Browser die ben&ouml;tigten Web Fonts in 
          ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
          Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den
          Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis
          dar&uuml;ber, dass &uuml;ber Ihre IP-Adresse diese Website aufgerufen
          wurde. Die Nutzung von Google WebFonts erfolgt auf Grundlage von Art. 6
          Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
          an der einheitlichen Darstellung des Schriftbildes auf seiner Website.
          Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine 
          Einwilligung zur Speicherung von Cookies), erfolgt die Verarbeitung 
          ausschlie&szlig;lich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die 
          Einwilligung ist jederzeit widerrufbar. Wenn Ihr Browser Web 
          Fonts nicht unterst&uuml;tzt, wird eine Standardschrift von Ihrem 
          Computer genutzt.
          <br><br>
          Weitere Informationen zu Google Web Fonts finden Sie unter
          <a href="https://developers.google.com/fonts/faq" target="_blank" rel="noopener noreferrer">
          https://developers.google.com/fonts/faq</a> und in der
          Datenschutzerkl&auml;rung von Google:
          <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
        </p>
        <h1 class="title title-step is-size-5 is-family-primary">
          Hotjar
        </h1>
        <p class="is-family-primary has-text-black p-after-h p-anim">
          Wir nutzen Hotjar, um die Bedürfnisse unserer Nutzer besser zu
          verstehen und das Angebot und die Erfahrung auf dieser Webseite zu
          optimieren. Mithilfe der Technologie von Hotjar bekommen wir ein
          besseres Verständnis von den Erfahrungen unserer Nutzer
          (z.B. wieviel Zeit Nutzer auf welchen Seiten verbringen, welche Links
          sie anklicken, was sie mögen und was nicht etc.) und das hilft uns,
          unser Angebot am Feedback unserer Nutzer auszurichten. Hotjar arbeitet
          mit Cookies und anderen Technologien, um Daten über das Verhalten
          unserer Nutzer und über ihre Endgeräte zu erheben, insbesondere IP
          Adresse des Geräts (wird während Ihrer Website-Nutzung nur in
          anonymisierter Form erfasst und gespeichert), Bildschirmgröße,
          Gerätetyp (Unique Device Identifiers), Informationen über den
          verwendeten Browser, Standort (nur Land), zum Anzeigen unserer
          Webseite bevorzugte Sprache. Hotjar speichert diese Informationen in
          unserem Auftrag in einem pseudonymisierten Nutzerprofil. Hotjar ist es
          vertraglich verboten, die in unserem Auftrag erhobenen Daten zu
          verkaufen.
          <br><br><br><br>
          Quelle: <a href="https://www.e-recht24.de" target="_blank">eRecht24</a>
          <br><br><br><br>
        </p>
      </div>
    </section>
  </div>  
</template>

<script>
// import IconBase from '../components/IconBase.vue'
// import IconArrow from '../components/icons/IconArrow.vue'
// import IconShortArrow from '../components/icons/IconShortArrow.vue'

export default {
  name: 'PrivacyContent',
  components: {
    // IconBase,
    // IconArrow,
    // IconShortArrow
  },
  data () {
    return {
  
    }
  },
  computed: {
    
  },
  mounted () {
    // if route
    let bg = document.querySelector('.body-bg');
    bg.classList.add('pure-bg');
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
